.content {
  margin: 120px auto;
  max-width: 500px;
}

.message {
  font: var(--typography-paragraph-m);
  margin-top: var(--spacing-06);
}

.message > h1 {
  font: var(--typography-headline-04);
  margin-bottom: var(--spacing-02);
}

.backButton {
  margin-top: var(--spacing-04);
}
